import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const MEMBERS_PORTAL_URL = process.env.VUE_APP_MEMBERS_PORTAL_URL || '//z-dev-ft-members-portal.domain.nfmp.gov.ng'

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'home-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Home.vue')
  }]
},
{
  path: '/FISSD',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'FISSD',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/FISS_Department.vue')
  }]
},
{
  path: '/partners',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'partners',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Partners.vue')
  }]
},
{
  path: '/products',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/ProductLayout.vue'),
  children: [{
    path: '',
    name: 'products',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Products.vue')
  }]
},      
{
  path: '/dealersListing',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dealers-listing-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/DealersListing.vue')
  }]
},
{
  path: '/farmers',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'farmers',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Farmers.vue')
  }]
},
{
  path: '/guidelines',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/GuidelinesLayout.vue'),
  children: [{
    path: '',
    name: 'guidelines',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Guidelines.vue')
  }]
},
{
  path: '/signin',beforeEnter(to, from, next) {
    window.location.href = MEMBERS_PORTAL_URL
  }
},
{
  path: '/signup',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'signup-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Signup.vue')
  }]
},
{
  path: '/uploads/:membershipid',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'uploads',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Uploads.vue')
  }]
},
{
  path: '/ForgotPassword',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'ForgotPassword-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ForgotPasswordpage.vue')
  }]
},
{
  path: '/update_account',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'update_account',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/UpdateAccount.vue')
  }]
},
{
  path: '/uploads/update/:membershipid',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'updateuploads',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/UpdateUploadsPage.vue')
  }]
},
{
  path: '/branches',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'branches-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/BranchesPage.vue')
  }]
},
{
  path: '/whistleblowing',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'whistle-blowing-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/WhistleBlowing.vue')
  }]
},
{
  path: '/helpcenter',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'help-center-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/HelpCenter.vue')
  }]
},{
  path: '/contactus',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'contact-us-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ContactUs.vue')
  }]
},
{
  path: '/verify_account',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'account-verification-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/AccountVerification.vue')
  }]
},
{
  path: '/setpassword',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'set-password-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SetPasswordPage.vue')
  }]
},
{
  path: '/createpassword',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'createpassword',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/CreatePasswordPage.vue')
  }]
},{
  path: '/faq',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'faq-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/FaqPage.vue')
  }]
},{
  path: '/successfull',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'successful-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SuccessfulPage.vue')
  }]
},{
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
